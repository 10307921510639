<template>
  <card>
    <v-row>
      <v-col>
        <v-sheet>
          <v-calendar
            ref="calendar"
            v-model="value"
            :events="events"
            :event-color="getEventColor"
            :event-ripple="false"
            color="primary"
            type="week"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            :locale="$i18n.locale"
            @click:event="onEventClick"
            @mousedown:event="onEventMouseDown"
            @mousedown:time="onTimeMouseDown"
            @mousemove:time="onMouseMove"
            @mouseup:time="onMouseUp"
            @mouseleave.native="onEndDrag"
          >
            <template #event="{ event, timed, timeSummary }">
              <div class="v-event-draggable">
                <b>{{ event.name }}</b>
                <br />
                {{ timeSummary() }}
              </div>
              <div
                v-if="timed"
                class="v-event-drag-bottom"
                @mousedown.stop="extendBottom(event)"
              />
            </template>
          </v-calendar>
          <v-dialog
            v-model="editDialog"
            max-width="500px"
            @click:outside="onCancel"
          >
            <v-card color="grey lighten-4" min-width="350px" flat>
              <v-toolbar :color="editedEvent.color" dark>
                <v-btn icon>
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-toolbar-title v-html="editedEvent.name" />
                <v-spacer />
                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text>
                <v-form v-model="isValid">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        ref="name"
                        v-model="editedEvent.name"
                        :label="$t('machines.schedule.event.name')"
                        dense
                        @keydown.esc="onCancel"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-select
                        v-model="editedEvent.is_working"
                        :items="isWorking"
                        :menu-props="{ maxHeight: '400' }"
                        :label="$t('machines.schedule.event.is-working')"
                        :hint="$t('select-hint')"
                        persistent-hint
                        dense
                        @keydown.esc="onCancel"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <v-text-field
                        v-model="editedEvent.color"
                        v-mask="mask"
                        hide-details
                        class="ma-0 pa-0"
                        solo
                        @keydown.esc="onCancel"
                      >
                        <template #append>
                          <v-menu
                            v-model="menu"
                            top
                            nudge-bottom="105"
                            nudge-left="16"
                            :close-on-content-click="false"
                          >
                            <template #activator="{ on }">
                              <div :style="colorSelectorStyle" v-on="on" />
                            </template>
                            <v-card elevation="0">
                              <v-card-text class="pa-0">
                                <v-color-picker
                                  v-model="editedEvent.color"
                                  flat
                                  @keydown.esc="onCancel"
                                />
                              </v-card-text>
                            </v-card>
                          </v-menu>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-btn text color="secondary" @click="onCancel">
                  {{ $t('cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  :disabled="!isValid"
                  @click="onSave()"
                >
                  {{ $t('save') }}
                </v-btn>
                <v-btn
                  v-if="eventBackup"
                  color="primary"
                  text
                  @click="onDeleteEvent"
                >
                  <v-icon>mdi-delete</v-icon>
                  {{ $t('delete') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-sheet>
      </v-col>
    </v-row>
  </card>
</template>

<script>
import Card from '../../../../components/base/Card.vue';
import ScheduleService from '@/services/ScheduleService.js';
import _ from 'lodash';

export default {
  components: { Card },
  props: {
    machine: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    today: new Date(),

    value: '',
    op: '', // create, drag, resize
    events: [],

    editedEvent: {},
    editDialog: false,
    isValid: true,
    isWorking: [
      { text: 'Yes', value: true },
      { text: 'No', value: false }
    ],
    mask: '!#XXXXXXXX',
    menu: false,

    dragEvent: null,
    eventBackup: null,
    moved: false,
    dragStart: null,
    createEvent: null,
    startTime: null,
    extendOriginal: null
  }),
  computed: {
    monday() {
      let d = new Date();
      console.log('d:', d);
      let day = d.getDay();
      console.log('day:', day);
      let diff = d.getDate() - day + (day == 0 ? -6 : 1);
      console.log('diff:', diff);

      let mon = new Date(d.setDate(diff));
      mon.setHours(0);
      mon.setMinutes(0);
      mon.setSeconds(0);
      return mon;
    },

    colorSelectorStyle() {
      // const { color, menu } = this;
      return {
        backgroundColor: this.editedEvent.color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: this.menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out'
      };
    }
  },
  watch: {
    machine: {
      handler() {
        // console.log("machine changed:", this.machine.name);
        this.init();
      },
      deep: true
    }
  },
  mounted() {
    this.init();
  },
  activated() {
    // console.log("scheduleTemplate.activated. Machine: ", this.machine.name);
    this.init();
  },
  methods: {
    init() {
      this.$refs.calendar.scrollToTime('08:00');
      this.loadItems();
    },
    async loadItems() {
      this.loading = true;
      try {
        let events = await ScheduleService.getTemplateEvents(this.machine.id);

        console.log('loaded events:', events);

        // convert to calendar events
        this.events = events.map(function(e) {
          let start = this.toDate(e.day, e.start);
          let end = this.toDate(e.day, e.end);
          return {
            ...e,
            start: start,
            end: end,
            timed: true
          };
        }, this);

        console.log('events:', this.events);
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },

    async saveEvent(event) {
      // transform timestamps into HH:mm
      let cpy = Object.assign({}, event);
      let day = null;
      let hour = null;
      ({ day, hour } = this.fromTimestamp(event.start));
      cpy.day = day;
      cpy.start = hour;
      ({ day, hour } = this.fromTimestamp(event.end));
      if (day != cpy.day) {
        throw "Template event can't span multiple days";
      }
      cpy.end = hour;

      // console.log("day:", day, ", hour:", hour);
      // event.end = new Date(event.end)

      // console.log("start:", event.start)
      // console.log("end:", event.end)

      // return;

      console.log('saving template event:', cpy);
      try {
        let rsp = null;
        if (cpy.id != null) {
          rsp = await ScheduleService.patchTemplateEvent(cpy);
        } else {
          rsp = await ScheduleService.postTemplateEvent(this.machine.id, cpy);
        }
        console.log('rsp:', rsp);
        return rsp;
      } catch (err) {
        this.showError(this, err);
      }
    },

    toDate(day, t) {
      let d = new Date(this.monday);
      d.setDate(d.getDate() + day - 1);
      let s = t.split(':');
      d.setHours(parseInt(s[0]), parseInt(s[1]), 0, 0);
      return Math.trunc(d.getTime());
    },

    fromTimestamp(t) {
      let d = new Date(t);
      let day = d.getDay();
      if (day == 0) {
        day = 7;
      }
      let ret = {
        day: day,
        hour: `${d.getHours()}:${d.getMinutes()}`
      };

      console.log(t, ' => ', ret);
      return ret;
    },

    getEventColor(event) {
      return event.color;
    },

    onEventClick({ nativeEvent, event }) {
      this.debug('onClick');

      if (this.moved && this.dragEvent) {
        // do not display the window if event was just moved to a new place
        return;
      }
      this.openEditDialog(event);

      nativeEvent.stopPropagation();
    },

    openEditDialog(event) {
      const open = () => {
        this.editedEvent = event;
        this.eventBackup = Object.assign({}, event);
        setTimeout(() => {
          this.editDialog = true;
          this.focusInput();
        }, 10);
      };

      if (this.editDialog) {
        this.editDialog = false;
        setTimeout(open, 10);
      } else {
        open();
      }
    },

    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    onEventMouseDown({ event, timed }) {
      this.debug('onEventMouseDown');

      this.moved = false;

      if (event && timed) {
        this.dragEvent = event;
        this.op = 'drag';

        this.eventBackup = Object.assign({}, event);
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    onTimeMouseDown(tms) {
      this.debug('onTimeMouseDown');

      const mouse = this.toTime(tms);

      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;

        this.dragTime = mouse - start;
      } else {
        this.startTime = this.roundTime(mouse);
        this.createEvent = {
          name: `Event #${this.events.length}`,
          color: '#488137FF',
          is_working: true,
          machine: this.machine.id,
          start: this.startTime,
          end: this.startTime,
          timed: true
        };

        this.events.push(this.createEvent);
        console.log(this.events);
      }
    },
    extendBottom(event) {
      this.debug('extendBottom');

      this.dragEvent = event;
      this.op = 'resize';

      this.eventBackup = Object.assign({}, event);
      this.startTime = event.start;
      this.extendOriginal = event.end;
    },
    onMouseMove(tms) {
      // this.debug("MouseMove");

      this.moved = true;

      const mouse = this.toTime(tms);

      if (this.dragEvent) {
        if (this.op == 'drag' && this.dragTime !== null) {
          console.log('D');
          // drag existing event
          const start = this.dragEvent.start;
          const end = this.dragEvent.end;
          const duration = end - start;
          const newStartTime = mouse - this.dragTime;
          const newStart = this.roundTime(newStartTime);
          const newEnd = newStart + duration;

          this.dragEvent.start = newStart;
          this.dragEvent.end = newEnd;
        } else if (this.op == 'resize') {
          console.log('R');
          // resize existing event OR create a new one
          const mouseRounded = this.roundTime(mouse, false);
          const min = Math.min(mouseRounded, this.startTime);
          const max = Math.max(mouseRounded, this.startTime);

          let minD = new Date(min);
          let maxD = new Date(max);
          if (minD.getDay() != maxD.getDay()) {
            // can't span multiple days
            return;
          }

          this.dragEvent.start = min;
          this.dragEvent.end = max;
        }
      } else if (this.createEvent && this.startTime !== null) {
        console.log('?');

        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.startTime);
        const max = Math.max(mouseRounded, this.startTime);

        let minD = new Date(min);
        let maxD = new Date(max);
        if (minD.getDay() != maxD.getDay()) {
          // can't span multiple days
          return;
        }

        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    async onMouseUp() {
      this.debug('@onMouseUp');
      console.log('event:', event);

      // console.log(
      //   "onMouseUp; event:",
      //   event,
      //   "dragTime:",
      //   this.dragTime,
      //   "nativeElement:",
      //   nativeEvent
      // );

      if (this.dragEvent) {
        if (!_.isEqual(this.dragEvent, this.eventBackup)) {
          console.log(
            'event:',
            this.dragEvent,
            ', eventBackup:',
            this.eventBackup
          );
          try {
            let e = await this.saveEvent(this.dragEvent);
            console.log('event saved:', e);

            // console.log("events:", this.events);
          } catch (err) {
            this.showError(this, err);

            // restore
            // console.log("eventBackup:", this.eventBackup);
            Object.assign(this.dragEvent, this.eventBackup);
          }
        }
      } else if (this.createEvent) {
        this.editedEvent = this.createEvent;
        this.editDialog = true;
        this.focusInput();
      }

      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.startTime = null;
      this.extendOriginal = null;
      this.op = '';
    },
    onEndDrag() {
      this.debug('onEndDrag');
      if (this.dragEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        }
      } else if (this.createEvent) {
        const i = this.events.indexOf(this.createEvent);
        if (i !== -1) {
          this.events.splice(i, 1);
        }
      }

      this.createEvent = null;
      this.startTime = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    onCancel() {
      this.editDialog = false;
      this.eventBackup = null;

      if (!this.editedEvent.id) {
        // new event => remove
        this.events.pop();
      } else {
        // existing event => restore
        Object.assign(this.editedEvent, this.eventBackup);
      }
    },
    async onSave() {
      try {
        let e = await this.saveEvent(this.editedEvent);
        console.log('event saved:', e);
        this.editDialog = false;
        this.eventBackup = null;
      } catch (err) {
        this.showError(this, err);
      }
    },
    onDeleteEvent() {
      try {
        console.log('deleting: ', this.editedEvent);
        ScheduleService.deleteTemplateEvent(this.editedEvent.id);
        this.events = this.events.filter(e => e.id != this.editedEvent.id);
        this.eventBackup = null;
        this.editDialog = false;
      } catch (err) {
        this.showError(this, err);
      }
    },

    debug(label) {
      console.log(
        '@',
        label,
        'op:',
        this.op,
        'dragEvent:',
        this.dragEvent,
        ', dragTime:',
        this.dragTime,
        ', createEvent:',
        this.createEvent,
        ', startTime:',
        this.startTime,
        ', eventBackup:',
        this.eventBackup,
        ', moved:',
        this.moved
      );
    },
    focusInput() {
      console.log('focusInput:', this.$refs);
      setTimeout(() => {
        this.$refs.name.focus();
      }, 200);
    }

    // weekdayFormat(date) {
    //   return this.$t(`time.day-${date.weekday}`);
    // },
    // monthFormat(date) {
    //   return this.$t(`time.month-${date.month}`);
    // }
  }
};
</script>
<style scoped lang="scss">
.my-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 2px;
  background-color: #1867c0;
  color: #ffffff;
  border: 1px solid #1867c0;
  font-size: 12px;
  padding: 3px;
  cursor: pointer;
  margin-bottom: 1px;
  left: 4px;
  margin-right: 8px;
  position: relative;
}

.my-event.with-time {
  position: absolute;
  right: 4px;
  margin-right: 0px;
}

.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
